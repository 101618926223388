import React from "react"
import Layout from "../components/layout"
import Hero from "../components/business-solutions/hero"
import Iquote from "../components/business-solutions/quote"
import Performance from "../components/business-solutions/performance"
import Mindshift from "../components/business-solutions/mindshift"
import Highp from "../components/business-solutions/highperformance"

export default function businessSolutions() {
  return (
    <Layout>
    	<Hero />
		<Iquote />
		<Performance />
		<Mindshift />
		<Highp />
    </Layout>
  )
}
