/** @jsx jsx */
import { jsx } from "theme-ui"
import performanceIllust1 from "../../images/illustrations/mindset-business.jpg"
import { Link } from "gatsby"


const ColorBlock = ({ offset }) => (
  <div style={{ backgroundColor: "#f3f3f3" }}>
    <div className="relative overflow-hidden">
      <div className="mx-auto p-6 md:pt-12 sm:p-8">
 <div className="md:flex p-4 justify-center">
          <div className="ml-8 flex flex-col w-full md:w-1/2 lg:w-1/2 xl:w-1/2 ">
                <div className="mb-8">
                    <h2
                      style={{
                        fontWeight: 800,
                        fontSize: 28,
                      }}
                      className=" mt-4 uppercase tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                    >
                      Individual to Organization
                    </h2>
                    <h2
                      style={{
                        fontWeight: 800,
                        fontSize: 36,
                        color: "#cc3366"
                      }}
                      className="uppercase mt-1 tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                    >
                      High transformation
                    </h2>
                    <h3 className="uppercase mt-6 ml-1" style={{
                        fontWeight: 600,
                        fontSize: 20,
                      }}>
                       &#8594; Recognize their Strengths
                    </h3>

                    <h3 className="uppercase mt-1 ml-1" style={{
                        fontWeight: 600,
                        fontSize: 20,
                      }}>
                      &#8594; Increase the Confidence
                    </h3>

                    <h3 className="uppercase mt-1 ml-1" style={{
                        fontWeight: 600,
                        fontSize: 20,
                      }}>
                      &#8594; Build Positive Mindsets
                    </h3>

                    <h3 className="uppercase mt-1 ml-1" style={{
                        fontWeight: 600,
                        fontSize: 20,
                      }}>
                      &#8594; Increase Critical Thinking
                    </h3>

                    <h3 className="uppercase mt-1 ml-1" style={{
                        fontWeight: 600,
                        fontSize: 20,
                      }}>
                      &#8594; Active Collaboration
                    </h3>

                    <h3 className="uppercase mt-1 ml-1" style={{
                        fontWeight: 600,
                        fontSize: 20,
                      }}>
                      &#8594; Swift Decision-making
                    </h3>

                </div>
                <div className="">
                    <Link
                      target="_blank"
                      to={"https://form.typeform.com/to/bbjcdIjn"}
                      className="cursor-pointer shadow bg-zone-cta focus:shadow-outline focus:outline-none text-white font-bold py-3 px-6 rounded"
                    >
                      Request a demo
                    </Link>
                    </div>
            </div>
            <div className="ml-8 flex flex-col w-full md:w-1/2 lg:w-1/2 xl:w-1/2 ">
                <img
                  style={{ width: 480 }}
                  className="w-auto h-auto"
                  src={performanceIllust1}
                  alt="illustration"
                />
              </div>

        </div>
      </div>
    </div>
  </div>
)

export default ColorBlock
