/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import icon_goal from "../../images/icons/icon_goal.png"
import styled from "styled-components"
import { Link } from "gatsby"

const Header = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

export default class Schedule extends React.Component {
  state = {
    name: "",
    email: "",
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    })

    this.setState({
      mailToString:
        "/schedule-demo?name=" + this.state.name + "&email=" + this.state.email,
    })
  }

  render() {
    return (
      <div>
        <div className="relative overflow-hidden">
          <div className="max-w-screen-xl mx-auto p-6 md:pt-12 sm:p-8">
          <Header>
          {/*  */}
     
          <h2
            style={{
                fontWeight: 800,
              fontSize: 28,
              }}
            className="uppercase text-center tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl mb-2"
          >
            GROWTH AND TRANSFORMATION
          </h2>
          <h2 
           style={{
                fontWeight: 800,
                fontSize: 36,
                color: "#cc3366",
              }}
          className="uppercase text-center tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl mb-2">
            THE WHOLE ORGANIZATION
          </h2>

          <p className=" text-center font-bold para">
            Insights and data-driven Coaching 
          </p>
        </Header>
        <div className="mt-8 flex flex-wrap justify-evenly">
          <div className="flex flex-col w-full md:w-1/4 lg:w-1/4 xl:w-1/4 mb-8">
            <h1 className="ml-4 mr-8 uppercase" style={{ fontSize: 18 }} >
              Leadership
            </h1> 
            <div className="ml-8 mt-2">
              <div
                className="flex justify-center mt-2 mb-2"
                style={{ width: "56px", height: "56px", borderRadius: "50%", backgroundColor: "#ffcc00" }} >
                <img
                  style={{
                    width: "28px",
                    height: "auto",
                  }}
                  className="self-center"
                  src={icon_goal}
                  alt="illustration"
                />
              </div>
            </div>
            <div className="self-center">
            <p
                style={{textAlign: "left", fontColor: "black" }}
                className="ml-2 mr-6 text-xs sm:text-sm md:text-md para_s font-bold"
              >
                - Increase awareness
              </p>
              <p
                style={{textAlign: "left", fontColor: "black" }}
                className="ml-2 mr-6 text-xs sm:text-sm md:text-md para_s font-bold"
              >
                - Make quick decision-making
              </p>
              <p
                style={{textAlign: "left", fontColor: "black" }}
                className="ml-2 mr-6 text-xs sm:text-sm md:text-md para_s font-bold"
              >
                - Enhance critical thinking
              </p>
              <p
                style={{textAlign: "left", fontColor: "black" }}
                className="ml-2 mr-6 text-xs sm:text-sm md:text-md para_s font-bold"
              >
                - Leader as a Coach
              </p>
              
            </div>
          </div>
          <div className="flex flex-col w-full md:w-1/4 lg:w-1/4 xl:w-1/4 mb-12">
            <h1 className="ml-4 mr-8 uppercase" style={{ fontSize: 18 }} >
              Product Team
            </h1> 
            <div className="ml-8 mt-2">
              <div
                className="flex justify-center mt-2 mb-2"
                style={{ width: "56px", height: "56px", borderRadius: "50%", backgroundColor: "#ffcc00" }} >
                <img
                  style={{
                    width: "28px",
                    height: "auto",
                  }}
                  className="self-center"
                  src={icon_goal}
                  alt="illustration"
                />
              </div>
            </div>
            <div className="self-center">
              <p
                style={{textAlign: "left", fontColor: "black" }}
                className="ml-2 mr-6 text-xs sm:text-sm md:text-md para_s font-bold"
              >
                - Working with Priorities
              </p>
              <p
                style={{textAlign: "left", fontColor: "black" }}
                className="ml-2 mr-6 text-xs sm:text-sm md:text-md para_s font-bold"
              >
                - Managing stakeholders
              </p>
              <p
                style={{textAlign: "left", fontColor: "black" }}
                className="ml-2 mr-6 text-xs sm:text-sm md:text-md para_s font-bold"
              >
                - Bringing agility in process
              </p>
              <p
                style={{textAlign: "left", fontColor: "black" }}
                className="ml-2 mr-6 text-xs sm:text-sm md:text-md para_s font-bold"
              >
                - Enabling team collaboration
              </p>
              
            </div>
          </div>
          <div className="flex flex-col w-full md:w-1/4 lg:w-1/4 xl:w-1/4 mb-12">
            <h1 className="ml-4 mr-8 uppercase" style={{ fontSize: 18 }} >
              Business Strategy
            </h1> 
            <div className="ml-8 mt-2">
              <div
                className="flex justify-center mt-2 mb-2"
                style={{ width: "56px", height: "56px", borderRadius: "50%", backgroundColor: "#ffcc00" }} >
                <img
                  style={{
                    width: "28px",
                    height: "auto",
                  }}
                  className="self-center"
                  src={icon_goal}
                  alt="illustration"
                />
              </div>
            </div>
            <div className="self-center">
            <p
                style={{textAlign: "left", fontColor: "black" }}
                className="ml-2 mr-6 text-xs sm:text-sm md:text-md para_s font-bold"
              >
                - Show different perspective
              </p>
              <p
                style={{textAlign: "left", fontColor: "black" }}
                className="ml-2 mr-6 text-xs sm:text-sm md:text-md para_s font-bold"
              >
                - Help with GTM strategy
              </p>
              <p
                style={{textAlign: "left", fontColor: "black" }}
                className="ml-2 mr-6 text-xs sm:text-sm md:text-md para_s font-bold"
              >
                - Scaling your business
              </p>
              <p
                style={{textAlign: "left", fontColor: "black" }}
                className="ml-2 mr-6 text-xs sm:text-sm md:text-md para_s font-bold"
              >
                - Attract clients
              </p>
              
              
            </div>
          </div>
          
        </div>

        <div className="mt-2 flex flex-wrap justify-evenly">
          <div className="flex flex-col w-full md:w-1/4 lg:w-1/4 xl:w-1/4 mb-12">
            <h1 className="ml-4 mr-8 uppercase" style={{ fontSize: 18 }} >
              Communication
            </h1> 
            <div className="ml-8 mt-2">
              <div
                className="flex justify-center mt-2 mb-2"
                style={{ width: "56px", height: "56px", borderRadius: "50%", backgroundColor: "#ffcc00" }} >
                <img
                  style={{
                    width: "28px",
                    height: "auto",
                  }}
                  className="self-center"
                  src={icon_goal}
                  alt="illustration"
                />
              </div>
            </div>
            <div className="self-center">
              <p
                style={{textAlign: "left", fontColor: "black" }}
                className="ml-2 mr-2 text-xs sm:text-sm md:text-md para_s font-bold"
              >
                - Helping crucial conversations
              </p>
              <p
                style={{textAlign: "left", fontColor: "black" }}
                className="ml-2 mr-2 text-xs sm:text-sm md:text-md para_s font-bold"
              >
                - Build clarity and confidence
              </p>
              <p
                style={{textAlign: "left", fontColor: "black" }}
                className="ml-2 mr-2 text-xs sm:text-sm md:text-md para_s font-bold"
              >
                - Manage workplace conflict
              </p>
              <p
                style={{textAlign: "left", fontColor: "black" }}
                className="ml-2 mr-2 text-xs sm:text-sm md:text-md para_s font-bold"
              >
                - Cultivate active listening
              </p>
            </div>
          </div>


          <div className="flex flex-col w-full md:w-1/4 lg:w-1/4 xl:w-1/4 mb-12">
            <h1 className="ml-4 mr-8 uppercase" style={{ fontSize: 18 }} >
              Sales Performance
            </h1> 
            <div className="ml-8 mt-2">
              <div
                className="flex justify-center mt-2 mb-2"
                style={{ width: "56px", height: "56px", borderRadius: "50%", backgroundColor: "#ffcc00" }} >
                <img
                  style={{
                    width: "28px",
                    height: "auto",
                  }}
                  className="self-center"
                  src={icon_goal}
                  alt="illustration"
                />
              </div>
            </div>
            <div className="self-center">
             <p
                style={{textAlign: "left", fontColor: "black" }}
                className="ml-2 mr-6 text-xs sm:text-sm md:text-md para_s font-bold"
              >
                - Strengthen relationships
              </p>
              <p
                style={{textAlign: "left", fontColor: "black" }}
                className="ml-2 mr-6 text-xs sm:text-sm md:text-md para_s font-bold"
              >
                - Inspire self-motivation
              </p>
              <p
                style={{textAlign: "left", fontColor: "black" }}
                className="ml-2 mr-6 text-xs sm:text-sm md:text-md para_s font-bold"
              >
                - Focus on behaviors
              </p>
              <p
                style={{textAlign: "left", fontColor: "black" }}
                className="ml-2 mr-6 text-xs sm:text-sm md:text-md para_s font-bold"
              >
                - Provide ongoing feedback
              </p>
              
            </div>
          </div>
          <div className="flex flex-col w-full md:w-1/4 lg:w-1/4 xl:w-1/4 mb-12">
            <h1 className="ml-4 mr-8 uppercase" style={{ fontSize: 18 }} >
              Well-being
            </h1> 
            <div className="ml-8 mt-2">
              <div
                className="flex justify-center mt-2 mb-2"
                style={{ width: "56px", height: "56px", borderRadius: "50%", backgroundColor: "#ffcc00" }} >
                <img
                  style={{
                    width: "28px",
                    height: "auto",
                  }}
                  className="self-center"
                  src={icon_goal}
                  alt="illustration"
                />
              </div>
            </div>
            <div className="self-center">
              <p
                style={{textAlign: "left", fontColor: "black" }}
                className="mr-6 text-xs sm:text-sm md:text-md para_s font-bold"
              >
                - Building habits and routine
              </p>
              <p
                style={{textAlign: "left", fontColor: "black" }}
                className="mr-6 text-xs sm:text-sm md:text-md para_s font-bold"
              >
                - Breaking patterns
              </p>
              <p
                style={{textAlign: "left", fontColor: "black" }}
                className="mr-6 text-xs sm:text-sm md:text-md para_s font-bold"
              >
                - Releasing stress and anxiety
              </p>
              <p
                style={{textAlign: "left", fontColor: "black" }}
                className="mr-6 text-xs sm:text-sm md:text-md para_s font-bold"
              >
                - Behavior modification
              </p>
              
            </div>
          </div>
          

        </div>

          <div className="text-center mt-6 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
            <h2 
           style={{
                fontWeight: 800,
                fontSize: 36,
                color: "#333333",
              }}
          className="uppercase text-center tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl mb-2">
            Coaching for All
          </h2>
          </div>
          
          <div className="text-center mt-6 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
            <div className="flex flex-col w-full md:w-1/4 lg:w-1/4 xl:w-1/4 mb-4" style={{ backgroundColor: "#f2f2f2"}}>
              <h1 className="ml-4 mr-4 mt-4 mb-4 uppercase" style={{ fontSize: 24, textAlign: "left" }} >
                <span style={{ color: "#cc3366" }}>Startup Founders </span> and Team
              </h1>
            </div>
            <div className="flex flex-col w-full md:w-1/4 lg:w-1/4 xl:w-1/4 mb-4" style={{  backgroundColor: "#e3e3e3"}}>
              <h1 className="ml-4 mr-4 mt-4 mb-4 uppercase" style={{ fontSize: 24, textAlign: "left"  }} >
                <span style={{ color: "#cc3366" }}>Top Talent Pool </span> and New Hires
              </h1> 
            </div>
            <div className="flex flex-col w-full md:w-1/4 lg:w-1/4 xl:w-1/4 mb-4" style={{ backgroundColor: "#f4f4f4"}}>
              <h1 className="ml-4 mr-4 mt-4 mb-4 uppercase" style={{ fontSize: 24, textAlign: "left"  }} >
                <span style={{ color: "#cc3366" }}>Executives and  </span>CXOs
              </h1> 
            </div>
            <div className="flex flex-col w-full md:w-1/4 lg:w-1/4 xl:w-1/4 mb-4" style={{backgroundColor: "#e2e2e2"}}>
              <h1 className="ml-4 mr-4 mt-4 mb-4 uppercase" style={{ fontSize: 24, textAlign: "left"  }} >
                <span style={{ color: "#cc3366" }}>Accelerate Sales </span> Performance
              </h1> 
            </div>
          </div>
          <div className="text-center mt-6 sm:mt-2 sm:flex sm:justify-center lg:justify-center">
          <p className="w-2/3 text-center font-bold para">
            BeingZone Coaches are your external eyes and ears committed to providing an honest and accurate picture of your reality. Coaches create a new level of awareness and redesign your actions step by step.
          </p>
          </div>

         <div className="text-center mt-6 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
              <Link
                target="_blank"
                to={"https://form.typeform.com/to/bbjcdIjn"}
                className="cursor-pointer shadow bg-zone-cta focus:shadow-outline focus:outline-none text-white font-bold py-3 px-6 rounded"
              >
                Request a demo
              </Link>
          </div>

          </div>
        </div>
      </div>
    )
  }
}
