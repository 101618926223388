/** @jsx jsx */
import { jsx } from "theme-ui"

import performanceIllust1 from "../../images/illustrations/for-organizations-leadership.jpg"


const ColorBlock = ({ offset }) => (
  <div style={{ backgroundColor: "#342421" }}>
    <div className="relative overflow-hidden">
      <div className="mx-auto p-6 md:pt-12 sm:p-8">
          <div className="md:flex p-4 justify-center">
              <div className="ml-8 flex flex-col w-full md:w-1/2 lg:w-1/2 xl:w-1/2 ">
                <img
                  style={{ Width: 600 }}
                  className="w-auto h-auto"
                  src={performanceIllust1}
                  alt="illustration"
                />
              </div>

              <div className="ml-8 flex flex-col w-full md:w-1/2 lg:w-1/2 xl:w-1/2 ">
                <div className="">
                    <h2
                      style={{
                        fontWeight: 800,
                        fontSize: 32,
                      }}
                      className="mt-4 text-white uppercase tracking-tight leading-8 sm:leading-none font-bold text-3xl sm:text-3xl md:text-3xl lg:text-5xl"
                    >
                      Power of Leadership Mindset Shift
                    </h2>

                    <p
                      style={{
                        fontSize: 14,
                      }}
                      className="mt-4 para text-white"
                    >
                      BeingZone helps build that culture where leaders decide how they show up. Our expert coaches ignite leaders with the right fuel to focus, be conscious, and be integral. Empower your leaders to be in the "Being State," not just "Doing State."
                    </p>
                </div>

              </div>
            </div>
      </div>
    </div>
  </div>
)

export default ColorBlock
